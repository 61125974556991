import React, {
  useState,
  isValidElement,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
  prop,
  sortBy,
  contains,
} from 'ramda'
import { useLocation, matchPath, useHistory } from 'react-router-dom'
import {
  SidebarHeader,
  Sidebar,
  SidebarLink,
  SidebarLinks,
} from 'former-kit'
import { useDispatch } from 'react-redux'

import MenuHeader from './components/menuHeader'
import MenuFooter from './components/menuFooter'
import styles from './style.css'
import { requestLogout, changeStatusLoading } from '../../pages/Account/actions/actions'
import useIsAuthenticatedWithJwt from '../../validation/isJwtAuthenticated'
import env, {
  impersonate, liveUrl, testUrl, profileUrl,
} from '../../environment'
import analitica from './helpers/analitica'

function sendAnaliticaEvent (linksData, currentLinkClick, t) {
  const filterItemByPath = linksData
    .filter(x => x.path.includes(currentLinkClick.path))

  if (filterItemByPath[0] && filterItemByPath[0].subLinkPath) {
    return analitica.events.sidebar
      .menuItemClick(filterItemByPath[0].subLinkPath[0],
        t(filterItemByPath[0].title))
  }

  if (filterItemByPath[0] && !filterItemByPath[0].subLinkPath) {
    return analitica.events.sidebar.menuItemClick(filterItemByPath[0].value,
      t(filterItemByPath[0].title))
  }

  return null
}

const SidebarContainer = ({
  companyCnpj,
  companyName,
  links,
  onLinkClick,
  t,
  userEmail,
  userName,
}) => {
  const [linksData, setLinkData] = useState(links)
  const [active, setActive] = useState([])
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isJWTAuthenticated = useIsAuthenticatedWithJwt()

  const onLogout = async () => {
    dispatch(changeStatusLoading({ loading: true }))
    await dispatch(requestLogout())
    dispatch(changeStatusLoading({ loading: false }))
  }

  const changeRouteCollapse = (routesLink, path) => {
    const newRoutes = routesLink.map(link => ({
      ...link,
      collapsed: link.path === path ? !link.collapsed : false,
    }))
    return newRoutes
  }

  useEffect(() => {
    const currentRoute = links.find(
      route => matchPath(location.pathname, route)
    )

    if (currentRoute) {
      const path = currentRoute.subLinkPath ? currentRoute.subLinkPath[0] : null
      const newLinks = changeRouteCollapse(links, path)
      setLinkData(newLinks)
      setActive(
        currentRoute.subLinkPath ? currentRoute.subLinkPath : currentRoute.path
      )
    } else {
      setActive('')
    }
  }, [location.pathname, links])

  const handleClick = (linkData) => {
    if (linkData.sublinks) {
      const newLinks = changeRouteCollapse(linksData, linkData.path)
      setLinkData(newLinks)
      return
    }

    sendAnaliticaEvent(linksData, linkData, t)

    onLinkClick({
      active: true,
      isExternalLink: linkData.isExternalLink,
      path: linkData.path,
    })
  }

  const getOptions = () => {
    const options = [
      {
        action: onLogout,
        testId: 'user-popover-exit',
        title: t('pages.sidebar.footer.exit'),
      },
    ]

    if (isJWTAuthenticated) {
      const jwtItem = {
        action: () => window.open(profileUrl, '_blank'),
        testId: 'user-popover-profile',
        title: t('pages.sidebar.footer.profile'),
      }
      options.unshift(jwtItem)
    } else {
      const sessionIdItem = {
        action: () => history.push({ pathname: '/change-password' }),
        testId: 'user-popover-change-password',
        title: t('pages.sidebar.footer.change_password'),
      }
      options.unshift(sessionIdItem)
    }

    if (impersonate) {
      options.unshift({
        action: () => window.open('https://dashspagarme.canny.io/feature-requests?category=dash-pagarme-10', '_blank'),
        testId: 'user-popover-feedback',
        title: t('pages.sidebar.footer.leave_feedback'),
      })
    }

    options.unshift({
      action: () => window.open(env === 'live' ? testUrl : liveUrl),
      testId: 'user-popover-environment-redirect',
      title: t(`pages.sidebar.footer.redirect_to_${env === 'live' ? 'test' : 'live'}_environment`),
    })

    return options
  }

  return (
    <Sidebar>
      <div className={styles.headerContainer}>
        <SidebarHeader>
          <MenuHeader
            companyName={companyName}
            companyCnpj={companyCnpj}
          />
        </SidebarHeader>
      </div>
      <div className={styles.linksContainer}>
        <SidebarLinks>
          {sortBy(prop('relevance'), linksData).map(({
            collapsed,
            hasSeparator,
            iconRight,
            isExternalLink,
            path,
            showSubLink,
            sublinks,
            title,
            value,
          }) => {
            if (!showSubLink) {
              return (
                <SidebarLink
                  data-testid={`menu-${value}`}
                  key={path}
                  title={t(title).toUpperCase()}
                  active={contains(path, active)}
                  onClick={() => handleClick({
                    isExternalLink,
                    path,
                    sublinks,
                  })}
                  collapsed={collapsed}
                  hasSeparator={hasSeparator}
                  iconRight={iconRight}
                >
                  {sublinks
                    && sortBy(prop('relevance'), sublinks).map(sublink => (
                      <SidebarLink
                        data-testid={`submenu-${sublink.value}`}
                        active={contains(sublink.value, active)}
                        key={sublink.path}
                        onClick={() => handleClick(sublink)}
                        title={t(sublink.title)}
                        value={sublink.path}
                      />
                    ))
                  }
                </SidebarLink>
              )
            }
            return null
          })}
        </SidebarLinks>
      </div>
      <div className={styles.footerContainer}>
        <MenuFooter
          userName={userName}
          userEmail={userEmail}
          options={getOptions()}
        />
      </div>
    </Sidebar>
  )
}

SidebarContainer.propTypes = {
  companyCnpj: PropTypes.string,
  companyName: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    component: isValidElement,
    path: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  onLinkClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  userName: PropTypes.string,
}

SidebarContainer.defaultProps = {
  companyCnpj: '',
  companyName: '',
  userEmail: '',
  userName: '',
}

export default SidebarContainer
